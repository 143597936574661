import React from "react";
import CookiesPolicy from "../components/policies/cookies";

function Cookies() {
    return (
        <>
            <CookiesPolicy />
        </>
    )
}

export default Cookies;