import React from "react";
import Contact from "../components/contact/contact";
import ContactForm from '../components/join/contactForm';

function JoinUs(){
    return(
        <>
            <Contact />
            <ContactForm />
        </>        
    )
}

export default JoinUs;